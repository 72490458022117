/* eslint-disable camelcase */

export interface IBillingEntityRegion {
    id: number
    name: string
}

export interface IBillingEntityCountry {
    id: number
    name: string
    region: IBillingEntityRegion
}

export interface IBillingEntitySaleInCharge {
    id: number
    name: string
}

export interface IBillingEntityRegionApiArgs {
    id: number
    name: string
}

export interface IBillingEntityCountryApiArgs {
    id: number
    name: string
    region: IBillingEntityRegionApiArgs
}

export interface IBillingEntitySaleInChargeApiArgs {
    id: number
    name: string
    type: string
    email: string
}

export interface BillingEntityApiArgs {
    id?: number
    name?: string
    nickname?: string
    name_of_recipient?: string
    dv_salesforce_account_id?: string
    odoo_customer_id?: string
    company_registration_number?: string
    vat_number?: string
    first_line_currency?: string
    invoice_currency?: string
    payment_term?: number
    invoice_data_grouping?: 'BILLING_ENTITY' | 'ADVERTISER' | 'SOW' | 'CUSTOM'
    address?: string
    zipcode?: string
    city?: string
    country?: IBillingEntityCountryApiArgs
    country_id?: number
    reference?: string
    scibids_rib?: 'hsbc_dv'
    invoice_to?: Array<string>
    invoice_cc?: Array<string>
    invoice_bcc?: Array<string>
    drive_repository?: string
    is_brought_by_reseller?: boolean
    reseller_email_address?: string
    invoice_attachment_mode?: 'NONE' | 'CC' | 'BCC'
    typology?: string
    sale_in_charge?: IBillingEntitySaleInChargeApiArgs
    sale_in_charge_id?: number
    dv360_tc_update_contact_email?: string
}

export const TypologyChoices = [
  'MARKETER',
  'ITD',
  'Big6 - Omnicom',
  'Big6 - Interpublic',
  'Big6 - Publicis',
  'Big6 - Dentsu',
  'Big6 - Havas',
  'Big6 - GroupM',
  'Big6 - GroupM (Xaxis)'
]

export enum RebateModes {
    ANNUALY = 'Annualy',
    QUARTERLY = 'Quarterly',
    NONE = 'None'
}

type MsaValuesType = 'MONO' | 'MULTI_WITHOUT_AAA' | 'MULTI_WITH_AAA' | 'AAA' | 'XANDR' | 'TTD' | 'DV360'
export type MsaItemObjectType = { value: string, text: string }
type MsaObjectType = { [Property in MsaValuesType]: MsaItemObjectType }

export const MSA: MsaObjectType = {
  MONO: { value: 'MSA_MONO', text: 'MSA Monobilling without AAA' },
  MULTI_WITHOUT_AAA: { value: 'MSA_MULTI_WITHOUT_AAA', text: 'MSA Multibilling without AAA' },
  MULTI_WITH_AAA: { value: 'MSA_MULTI_WITH_AAA', text: 'MSA Multiilling with AAA' },
  AAA: { value: 'AAA', text: 'AAA' },
  XANDR: { value: 'XANDR_PARTNERSHIP', text: 'Xandr Partnership' },
  TTD: { value: 'TTD_PARTNERSHIP', text: 'TTD Partnership' },
  DV360: { value: 'DV360_PARTNERSHIP', text: 'DV360 Partnership' }
}

export const MSA_TYPES_WITH_INHERITANCE: Array<string> = [
  'MSA_MONO', 'MSA_MULTI_WITH_AAA', 'AAA', 'XANDR_PARTNERSHIP', 'TTD_PARTNERSHIP', 'DV360_PARTNERSHIP'
]

export enum InvoiceDataGroupingTypes {
    BILLING_ENTITY = 'Billing Entity',
    ADVERTISER = 'Advertiser',
    SOW = 'SOW',
    CUSTOM = 'Custom'
}

export enum ScibidsRibTypes {
    hsbc_dv = 'DV HSBC Account'
}

export enum FeeRateTypeItems {
    TIERED_RATE = 'Tiered Rate',
    VOLUME_RATE = 'Volume Rate',
    FLAT_RATE = 'Flat Rate',
    FIXED_FEE = 'Fixed Fee',
    SOW = 'SOW'
}

export enum TierConditionsHeaders {
    FROM = 'From (media cost)',
    TO = 'To (media cost)',
    RATE = 'Applied rate to the whole media cost'
}

export enum InvoiceCurrencyTypes {
    DZD = 'Afghani | Algerian Dinar',
    ALL = 'Lek',
    USD = 'US Dollar',
    EUR = 'Euro',
    AOA = 'Kwanza',
    XCD = 'East Caribbean Dollar',
    ARS = 'Argentine Peso',
    AMD = 'Armenian Dram',
    AWG = 'Aruban Florin',
    AUD = 'Australian Dollar',
    AZN = 'Azerbaijanian Manat',
    BSD = 'Bahamian Dollar',
    BHD = 'Bahraini Dinar',
    BDT = 'Taka',
    BBD = 'Barbados Dollar',
    BYN = 'Belarussian Ruble',
    BZD = 'Belize Dollar',
    XOF = 'CFA Franc BCEAO',
    BMD = 'Bermudian Dollar',
    BTN = 'Ngultrum',
    INR = 'Indian Rupee',
    BOB = 'Boliviano',
    BOV = 'Mvdol',
    BAM = 'Convertible Mark',
    BWP = 'Pula',
    NOK = 'Norwegian Krone',
    BRL = 'Brazilian Real',
    BND = 'Brunei Dollar',
    BGN = 'Bulgarian Lev',
    BIF = 'Burundi Franc',
    CVE = 'Cabo Verde Escudo',
    KHR = 'Riel',
    XAF = 'CFA Franc BEAC',
    CAD = 'Canadian Dollar',
    KYD = 'Cayman Islands Dollar',
    CLF = 'Unidad de Fomento',
    CLP = 'Chilean Peso',
    CNY = 'Yuan Renminbi',
    COP = 'Colombian Peso',
    COU = 'Unidad de Valor Real',
    KMF = 'Comoro Franc',
    CDF = 'Congolese Franc',
    NZD = 'New Zealand Dollar',
    CRC = 'Costa Rican Colon',
    HRK = 'Kuna',
    CUC = 'Peso Convertible',
    CUP = 'Cuban Peso',
    ANG = 'Netherlands Antillean Guilder',
    CZK = 'Czech Koruna',
    DKK = 'Danish Krone',
    DJF = 'Djibouti Franc',
    DOP = 'Dominican Peso',
    EGP = 'Egyptian Pound',
    SVC = 'El Salvador Colon',
    ERN = 'Nakfa',
    ETB = 'Ethiopian Birr',
    FKP = 'Falkland Islands Pound',
    FJD = 'Fiji Dollar',
    XPF = 'CFP Franc',
    GMD = 'Dalasi',
    GEL = 'Lari',
    GHS = 'Ghana Cedi',
    GIP = 'Gibraltar Pound',
    GTQ = 'Quetzal',
    GBP = 'Pound Sterling',
    GNF = 'Guinea Franc',
    GYD = 'Guyana Dollar',
    HTG = 'Gourde',
    HNL = 'Lempira',
    HKD = 'Hong Kong Dollar',
    HUF = 'Forint',
    ISK = 'Iceland Krona',
    IDR = 'Rupiah',
    XDR = 'SDR (Special Drawing Right)',
    IRR = 'Iranian Rial',
    IQD = 'Iraqi Dinar',
    ILS = 'New Israeli Sheqel',
    JMD = 'Jamaican Dollar',
    JPY = 'Yen',
    JOD = 'Jordanian Dinar',
    KZT = 'Tenge',
    KES = 'Kenyan Shilling',
    KPW = 'North Korean Won',
    KRW = 'Won',
    KWD = 'Kuwaiti Dinar',
    KGS = 'Som',
    LAK = 'Kip',
    LBP = 'Lebanese Pound',
    LSL = 'Loti',
    ZAR = 'Rand',
    LRD = 'Liberian Dollar',
    LYD = 'Libyan Dinar',
    CHF = 'Swiss Franc',
    MOP = 'Pataca',
    MGA = 'Malagasy Ariary',
    MWK = 'Kwacha',
    MYR = 'Malaysian Ringgit',
    MVR = 'Rufiyaa',
    MRU = 'Ouguiya',
    MUR = 'Mauritius Rupee',
    XUA = 'ADB Unit of Account',
    MXN = 'Mexican Peso',
    MXV = 'Mexican Unidad de Inversion (UDI)',
    MDL = 'Moldovan Leu',
    MNT = 'Tugrik',
    MAD = 'Moroccan Dirham',
    MZN = 'Mozambique Metical',
    MMK = 'Kyat',
    NAD = 'Namibia Dollar',
    NPR = 'Nepalese Rupee',
    NIO = 'Cordoba Oro',
    NGN = 'Naira',
    OMR = 'Rial Omani',
    PKR = 'Pakistan Rupee',
    PAB = 'Balboa',
    PGK = 'Kina',
    PYG = 'Guarani',
    PEN = 'Nuevo Sol',
    PHP = 'Philippine Peso',
    PLN = 'Zloty',
    QAR = 'Qatari Rial',
    MKD = 'Denar',
    RON = 'Romanian Leu',
    RUB = 'Russian Ruble',
    RWF = 'Rwanda Franc',
    SHP = 'Saint Helena Pound',
    WST = 'Tala',
    STN = 'Dobra',
    SAR = 'Saudi Riyal',
    RSD = 'Serbian Dinar',
    SCR = 'Seychelles Rupee',
    SLL = 'Leone',
    SGD = 'Singapore Dollar',
    XSU = 'Sucre',
    SBD = 'Solomon Islands Dollar',
    SOS = 'Somali Shilling',
    SSP = 'South Sudanese Pound',
    LKR = 'Sri Lanka Rupee',
    SDG = 'Sudanese Pound',
    SRD = 'Surinam Dollar',
    SZL = 'Lilangeni',
    SEK = 'Swedish Krona',
    CHE = 'WIR Euro',
    CHW = 'WIR Franc',
    SYP = 'Syrian Pound',
    TWD = 'New Taiwan Dollar',
    TJS = 'Somoni',
    TZS = 'Tanzanian Shilling',
    THB = 'Baht',
    TOP = 'Pa’anga',
    TTD = 'Trinidad and Tobago Dollar',
    TND = 'Tunisian Dinar',
    TRY = 'Turkish Lira',
    TMT = 'Turkmenistan New Manat',
    UGX = 'Uganda Shilling',
    UAH = 'Hryvnia',
    AED = 'UAE Dirham',
    USN = 'US Dollar (Next day)',
    UYI = 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
    UYU = 'Peso Uruguayo',
    UZS = 'Uzbekistan Sum',
    VUV = 'Vatu',
    VEF = 'Bolivar',
    VND = 'Dong',
    YER = 'Yemeni Rial',
    ZMW = 'Zambian Kwacha',
    ZWL = 'Zimbabwe Dollar',
}

export const CountryToAlpha2Codes: {[x: string]: string} = {
  'Afghanistan': 'AF',
  'Albania': 'AL',
  'Algeria': 'DZ',
  'American Samoa': 'AS',
  'Andorra': 'AD',
  'Angola': 'AO',
  'Anguilla': 'AI',
  'Antarctica': 'AQ',
  'Antigua and Barbuda': 'AG',
  'Argentina': 'AR',
  'Armenia': 'AM',
  'Aruba': 'AW',
  'Australia': 'AU',
  'Austria': 'AT',
  'Azerbaijan': 'AZ',
  'Bahamas': 'BS',
  'Bahrain': 'BH',
  'Bangladesh': 'BD',
  'Barbados': 'BB',
  'Belarus': 'BY',
  'Belgium': 'BE',
  'Belize': 'BZ',
  'Benin': 'BJ',
  'Bermuda': 'BM',
  'Bhutan': 'BT',
  'Bolivia (Plurinational State of)': 'BO',
  'Bonaire, Sint Eustatius and Saba': 'BQ',
  'Bosnia and Herzegovina': 'BA',
  'Botswana': 'BW',
  'Bouvet Island': 'BV',
  'Brazil': 'BR',
  'British Indian Ocean Territory': 'IO',
  'Brunei Darussalam': 'BN',
  'Bulgaria': 'BG',
  'Burkina Faso': 'BF',
  'Burundi': 'BI',
  'Cabo Verde': 'CV',
  'Cambodia': 'KH',
  'Cameroon': 'CM',
  'Canada': 'CA',
  'Cayman Islands': 'KY',
  'Central African Republic': 'CF',
  'Chad': 'TD',
  'Chile': 'CL',
  'China': 'CN',
  'Christmas Island': 'CX',
  'Cocos (Keeling) Islands': 'CC',
  'Colombia': 'CO',
  'Comoros': 'KM',
  'Congo, Democratic Republic of the': 'CD',
  'Congo': 'CG',
  'Cook Islands': 'CK',
  'Costa Rica': 'CR',
  'Croatia': 'HR',
  'Cuba': 'CU',
  'Curaçao': 'CW',
  'Cyprus': 'CY',
  'Czechia': 'CZ',
  'Côte d\'Ivoire': 'CI',
  'Denmark': 'DK',
  'Djibouti': 'DJ',
  'Dominica': 'DM',
  'Dominican Republic': 'DO',
  'Ecuador': 'EC',
  'Egypt': 'EG',
  'El Salvador': 'SV',
  'Equatorial Guinea': 'GQ',
  'Eritrea': 'ER',
  'Estonia': 'EE',
  'Eswatini': 'SZ',
  'Ethiopia': 'ET',
  'Falkland Islands (Malvinas)': 'FK',
  'Faroe Islands': 'FO',
  'Fiji': 'FJ',
  'Finland': 'FI',
  'France': 'FR',
  'French Guiana': 'GF',
  'French Polynesia': 'PF',
  'French Southern Territories': 'TF',
  'Gabon': 'GA',
  'Gambia': 'GM',
  'Georgia': 'GE',
  'Germany': 'DE',
  'Ghana': 'GH',
  'Gibraltar': 'GI',
  'Greece': 'GR',
  'Greenland': 'GL',
  'Grenada': 'GD',
  'Guadeloupe': 'GP',
  'Guam': 'GU',
  'Guatemala': 'GT',
  'Guernsey': 'GG',
  'Guinea': 'GN',
  'Guinea-Bissau': 'GW',
  'Guyana': 'GY',
  'Haiti': 'HT',
  'Heard Island and McDonald Islands': 'HM',
  'Holy See': 'VA',
  'Honduras': 'HN',
  'Hong Kong': 'HK',
  'Hungary': 'HU',
  'Iceland': 'IS',
  'India': 'IN',
  'Indonesia': 'ID',
  'Iran (Islamic Republic of)': 'IR',
  'Iraq': 'IQ',
  'Ireland': 'IE',
  'Isle of Man': 'IM',
  'Israel': 'IL',
  'Italy': 'IT',
  'Jamaica': 'JM',
  'Japan': 'JP',
  'Jersey': 'JE',
  'Jordan': 'JO',
  'Kazakhstan': 'KZ',
  'Kenya': 'KE',
  'Kiribati': 'KI',
  'Korea (Democratic People\'s Republic of)': 'KP',
  'Korea, Republic of': 'KR',
  'Kuwait': 'KW',
  'Kyrgyzstan': 'KG',
  'Lao People\'s Democratic Republic': 'LA',
  'Latvia': 'LV',
  'Lebanon': 'LB',
  'Lesotho': 'LS',
  'Liberia': 'LR',
  'Libya': 'LY',
  'Liechtenstein': 'LI',
  'Lithuania': 'LT',
  'Luxembourg': 'LU',
  'Macao': 'MO',
  'Madagascar': 'MG',
  'Malawi': 'MW',
  'Malaysia': 'MY',
  'Maldives': 'MV',
  'Mali': 'ML',
  'Malta': 'MT',
  'Marshall Islands': 'MH',
  'Martinique': 'MQ',
  'Mauritania': 'MR',
  'Mauritius': 'MU',
  'Mayotte': 'YT',
  'Mexico': 'MX',
  'Micronesia (Federated States of)': 'FM',
  'Moldova, Republic of': 'MD',
  'Monaco': 'MC',
  'Mongolia': 'MN',
  'Montenegro': 'ME',
  'Montserrat': 'MS',
  'Morocco': 'MA',
  'Mozambique': 'MZ',
  'Myanmar': 'MM',
  'Namibia': 'NA',
  'Nauru': 'NR',
  'Nepal': 'NP',
  'Netherlands': 'NL',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  'Nicaragua': 'NI',
  'Niger': 'NE',
  'Nigeria': 'NG',
  'Niue': 'NU',
  'Norfolk Island': 'NF',
  'Northern Mariana Islands': 'MP',
  'Norway': 'NO',
  'Oman': 'OM',
  'Pakistan': 'PK',
  'Palau': 'PW',
  'Palestine, State of': 'PS',
  'Panama': 'PA',
  'Papua New Guinea': 'PG',
  'Paraguay': 'PY',
  'Peru': 'PE',
  'Philippines': 'PH',
  'Pitcairn': 'PN',
  'Poland': 'PL',
  'Portugal': 'PT',
  'Puerto Rico': 'PR',
  'Qatar': 'QA',
  'North Macedonia': 'MK',
  'Romania': 'RO',
  'Russian Federation': 'RU',
  'Rwanda': 'RW',
  'Réunion': 'RE',
  'Saint Barthélemy': 'BL',
  'Saint Helena, Ascension and Tristan da Cunha': 'SH',
  'Saint Kitts and Nevis': 'KN',
  'Saint Lucia': 'LC',
  'Saint Martin (French part)': 'MF',
  'Saint Pierre and Miquelon': 'PM',
  'Saint Vincent and the Grenadines': 'VC',
  'Samoa': 'WS',
  'San Marino': 'SM',
  'Sao Tome and Principe': 'ST',
  'Saudi Arabia': 'SA',
  'Senegal': 'SN',
  'Serbia': 'RS',
  'Seychelles': 'SC',
  'Sierra Leone': 'SL',
  'Singapore': 'SG',
  'Sint Maarten (Dutch part)': 'SX',
  'Slovakia': 'SK',
  'Slovenia': 'SI',
  'Solomon Islands': 'SB',
  'Somalia': 'SO',
  'South Africa': 'ZA',
  'South Georgia and the South Sandwich Islands': 'GS',
  'South Sudan': 'SS',
  'Spain': 'ES',
  'Sri Lanka': 'LK',
  'Sudan': 'SD',
  'Suriname': 'SR',
  'Svalbard and Jan Mayen': 'SJ',
  'Sweden': 'SE',
  'Switzerland': 'CH',
  'Syrian Arab Republic': 'SY',
  'Taiwan': 'TW',
  'Tajikistan': 'TJ',
  'Tanzania, United Republic of': 'TZ',
  'Thailand': 'TH',
  'Timor-Leste': 'TL',
  'Togo': 'TG',
  'Tokelau': 'TK',
  'Tonga': 'TO',
  'Trinidad and Tobago': 'TT',
  'Tunisia': 'TN',
  'Turkey': 'TR',
  'Turkmenistan': 'TM',
  'Turks and Caicos Islands': 'TC',
  'Tuvalu': 'TV',
  'Uganda': 'UG',
  'Ukraine': 'UA',
  'United Arab Emirates': 'AE',
  'United Kingdom of Great Britain and Northern Ireland': 'GB',
  'United States Minor Outlying Islands': 'UM',
  'United States of America': 'US',
  'Uruguay': 'UY',
  'Uzbekistan': 'UZ',
  'Vanuatu': 'VU',
  'Venezuela (Bolivarian Republic of)': 'VE',
  'Viet Nam': 'VN',
  'Virgin Islands (British)': 'VG',
  'Virgin Islands (U.S.)': 'VI',
  'Wallis and Futuna': 'WF',
  'Western Sahara': 'EH',
  'Yemen': 'YE',
  'Zambia': 'ZM',
  'Zimbabwe': 'ZW',
  'Åland Islands': 'AX'
}
